// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
  width: 100%;
  height: 100%; }

.dashboard-content-container {
  display: flex;
  height: calc(100% - 3.5rem); }

.dashboard-content {
  width: 100%;
  padding: 1rem;
  overflow-y: auto; }

.dashboard-content .ant-menu {
  height: 100%; }
`, "",{"version":3,"sources":["webpack://./src/containers/Dashboard/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,2BAA2B,EAAA;;AAG7B;EACE,WAAW;EACX,aAAa;EACb,gBAAgB,EAAA;;AAGlB;EACE,YAAY,EAAA","sourcesContent":[".dashboard-container{\n  width: 100%;\n  height: 100%;\n}\n\n.dashboard-content-container{\n  display: flex;\n  height: calc(100% - 3.5rem);\n}\n\n.dashboard-content{\n  width: 100%;\n  padding: 1rem;\n  overflow-y: auto;\n}\n\n.dashboard-content .ant-menu {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
