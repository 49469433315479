// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 1rem;
  height: 3.5rem; }
`, "",{"version":3,"sources":["webpack://./src/components/Header/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,cAAc,EAAA","sourcesContent":[".header-container{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: white;\n  padding: 0 1rem;\n  height: 3.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
