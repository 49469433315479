// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  background-color: #fcffff; }

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 1px; }

.legend-color {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border: 1px solid #999; }

.legend-label {
  font-size: 14px; }
`, "",{"version":3,"sources":["webpack://./src/containers/Dashboard/History/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,aAAa;EACb,kBAAkB;EAClB,yBAAyB,EAAA;;AAG3B;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB,EAAA;;AAGpB;EACE,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,sBAAsB,EAAA;;AAGxB;EACE,eAAe,EAAA","sourcesContent":[".legend {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 10px;\n  border-radius: 5px;\n  background-color: #fcffff;\n}\n\n.legend-item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 1px;\n}\n\n.legend-color {\n  width: 8px;\n  height: 8px;\n  margin-right: 5px;\n  border: 1px solid #999;\n}\n\n.legend-label {\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
