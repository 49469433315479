import './App.css';
import MainRouter from './router';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <MainRouter/>
  );
}

export default App;
